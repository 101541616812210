




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import Contactm from "@/views/Mine/Set/Contactm.vue";

@Component({
  components: {
    Contactm,
  },
})
export default class ContactmPage extends Mixins(Mixin) {}
