







































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row } from "vant";
import StatusBar from "@/components/StatusBar.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    StatusBar,
  },
})
export default class Contactm extends Mixins(Mixin) {
  onClickLeft(): void {
    if (this.AppSource == "harmonyOS") {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    } else {
      this.$router.go(-1);
    }
  }
}
